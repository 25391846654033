import React from 'react'
import { Helmet } from 'react-helmet'

export default function Seo(props) {
  return (
    <main>
        <Helmet>
            <title>{`${props.pagetitle} | Verdant Consulting`}</title>
            <meta name="description" content={props.pagedescription}/>
        </Helmet>
    </main>
  )
}
