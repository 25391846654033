import React from 'react';
// import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import fb from '../images/Facebook.png'
import tw from '../images/Twitter.png'
import ins from '../images/Instagram.png'
import Logo from './Logo';

export default function Nav(props) {

    //state
    const [showmenu, setShowMenu]=React.useState(false);

    const handleClick=function(){
        setShowMenu((prevstate)=>{
            return(!prevstate);
        });
    }

  return (
      <main>
        {/* Top - obsolete */}
        <div id="top-menu" className='hidden relative bg-orange-400'>
            {/* Top menu for default, sm & md */}
            <div className=' w-11/12 mx-auto py-1 flex items-center space-x-2 sm:w-8/12 sm:justify-between lg:hidden'>
                <div id="the svg">
                    <svg className='h-6 w-6' viewBox="0 0 40.939 69.855" xmlns="http://www.w3.org/2000/svg">
                    <path d="M126.062 58.005s-12.171-.78-15.748 2.796a9.16 9.16 0 0 0 12.953 12.952c3.576-3.576 2.795-15.748 2.795-15.748zm23.128-.835c-1.749.432-3.281 1.07-4.22 2.01a9.16 9.16 0 0 0 12.951 12.953c3.028-3.028 2.933-12.213 2.833-14.962h-11.563zm-24.02 24.277a9.158 9.158 0 0 0 0 12.952 9.158 9.158 0 0 0 12.952 0c3.577-3.576 2.796-15.748 2.796-15.748s-12.17-.78-15.748 2.796zm67.333-77.259c0-1.614-4.028-2.752-4.372-4.262-.354-1.56 2.773-4.33 2.094-5.737-.69-1.43-4.813-.708-5.793-1.936-.985-1.234.631-5.092-.603-6.078-1.227-.98-4.63 1.45-6.058.76-1.407-.678-1.622-4.859-3.181-5.214-1.51-.343-3.512 3.323-5.127 3.323-1.614 0-3.617-3.666-5.126-3.323-1.559.355-1.774 4.536-3.18 5.215-1.43.69-4.832-1.741-6.06-.76-.564.45-.531 1.504-.425 2.632a31.094 31.094 0 0 1 4.458 5.009c2.672-2.663 6.338-4.327 10.334-4.327 8.01 0 14.699 6.688 14.699 14.699 0 1.606-.273 3.157-.768 4.614a26.247 26.247 0 0 1 3.359 2.57 25.66 25.66 0 0 1 3.04 3.273 1.13 1.13 0 0 0 .432-.457c.679-1.406-2.449-4.178-2.094-5.737.343-1.511 4.37-2.65 4.37-4.264zM166.735 8.05a20.9 20.9 0 0 0-9.623 2.336c.003-.158.006-.317.006-.476 0-14.241-11.545-25.787-25.787-25.787-13.962 0-25.332 11.098-25.771 24.955-8.418 2.736-14.505 10.64-14.505 19.97 0 11.596 9.401 20.998 20.998 20.998h54.682c11.597 0 20.998-9.402 20.998-20.998 0-11.597-9.401-20.999-20.998-20.999z" fill="#15803d"/>
                    <path d="M20.47 0C9.183 0 0 9.183 0 20.47a20.282 20.282 0 0 0 2.065 8.97l15.466 29.945a3.308 3.308 0 0 0 5.877 0L38.87 29.448a20.28 20.28 0 0 0 2.07-8.978C40.939 9.183 31.756 0 20.469 0Zm0 31.184c-5.908 0-10.715-4.806-10.715-10.714S14.562 9.756 20.47 9.756c5.908 0 10.714 4.806 10.714 10.714S26.378 31.184 20.47 31.184zm7.885 34.702h-15.77a1.984 1.984 0 1 0 0 3.969h15.77a1.984 1.984 0 1 0 0-3.969z" fill="#fff"/>
                    </svg>
                </div>
                <div id="the location">
                <p className='text-rt text-gray-100 tracking-tight'>Kakungulu Road, Minister’s Village, Ntinda Kampala – Uganda</p>
                </div>
            </div>

            {/* Top menu for lg + */}
            <div id="location + socials" className='hidden lg:block lg:flex items-center justify-between px-20'>
                <div id="location">
                    <div className='py-1 flex items-center space-x-4'>
                        <div id="the svg">
                            <svg className='h-6 w-6' viewBox="0 0 40.939 69.855" xmlns="http://www.w3.org/2000/svg">
                            <path d="M126.062 58.005s-12.171-.78-15.748 2.796a9.16 9.16 0 0 0 12.953 12.952c3.576-3.576 2.795-15.748 2.795-15.748zm23.128-.835c-1.749.432-3.281 1.07-4.22 2.01a9.16 9.16 0 0 0 12.951 12.953c3.028-3.028 2.933-12.213 2.833-14.962h-11.563zm-24.02 24.277a9.158 9.158 0 0 0 0 12.952 9.158 9.158 0 0 0 12.952 0c3.577-3.576 2.796-15.748 2.796-15.748s-12.17-.78-15.748 2.796zm67.333-77.259c0-1.614-4.028-2.752-4.372-4.262-.354-1.56 2.773-4.33 2.094-5.737-.69-1.43-4.813-.708-5.793-1.936-.985-1.234.631-5.092-.603-6.078-1.227-.98-4.63 1.45-6.058.76-1.407-.678-1.622-4.859-3.181-5.214-1.51-.343-3.512 3.323-5.127 3.323-1.614 0-3.617-3.666-5.126-3.323-1.559.355-1.774 4.536-3.18 5.215-1.43.69-4.832-1.741-6.06-.76-.564.45-.531 1.504-.425 2.632a31.094 31.094 0 0 1 4.458 5.009c2.672-2.663 6.338-4.327 10.334-4.327 8.01 0 14.699 6.688 14.699 14.699 0 1.606-.273 3.157-.768 4.614a26.247 26.247 0 0 1 3.359 2.57 25.66 25.66 0 0 1 3.04 3.273 1.13 1.13 0 0 0 .432-.457c.679-1.406-2.449-4.178-2.094-5.737.343-1.511 4.37-2.65 4.37-4.264zM166.735 8.05a20.9 20.9 0 0 0-9.623 2.336c.003-.158.006-.317.006-.476 0-14.241-11.545-25.787-25.787-25.787-13.962 0-25.332 11.098-25.771 24.955-8.418 2.736-14.505 10.64-14.505 19.97 0 11.596 9.401 20.998 20.998 20.998h54.682c11.597 0 20.998-9.402 20.998-20.998 0-11.597-9.401-20.999-20.998-20.999z" fill="#15803d"/>
                            <path d="M20.47 0C9.183 0 0 9.183 0 20.47a20.282 20.282 0 0 0 2.065 8.97l15.466 29.945a3.308 3.308 0 0 0 5.877 0L38.87 29.448a20.28 20.28 0 0 0 2.07-8.978C40.939 9.183 31.756 0 20.469 0Zm0 31.184c-5.908 0-10.715-4.806-10.715-10.714S14.562 9.756 20.47 9.756c5.908 0 10.714 4.806 10.714 10.714S26.378 31.184 20.47 31.184zm7.885 34.702h-15.77a1.984 1.984 0 1 0 0 3.969h15.77a1.984 1.984 0 1 0 0-3.969z" fill="#fff"/>
                            </svg>
                        </div>
                        <div id="the location">
                        <p className='text-xs text-gray-100 tracking-tight'>Kakungulu Road, Minister’s Village, Ntinda Kampala – Uganda</p>
                        </div>
                    </div>
                </div>
                <div id="the socials" className='flex items-center space-x-1'>
                    <Link to="#"><div id="fb"><img src={fb} alt="Facebook/Meta logo" className='h-6 w-6'/></div></Link>
                    <Link to="#"><div id="tw"><img src={tw} alt="Twitter logo" className='h-6 w-6'/></div></Link>
                    <Link to="#"><div id="in"><img src={ins} alt="Instagram logo" className='h-6 w-6'/></div></Link>
                </div>               
            </div>

        </div>

      {/* Middle */}
      {/* default, sm & md middle */}
        <div id="logo + burger" className='pt-4 pb-10 w-11/12 mx-auto flex items-center justify-between sm:w-10/12 lg:hidden'>
            <div id="logo" className='-mt-2 '>
            {/* <Link to="/"><StaticImage src="../images/verdantlogo-mob.png" alt="verdant logo" width={100}/></Link> */}
            <Logo/>
            </div>
            <div id="burger">
                <button onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg> 
                </button>                
            </div>
        </div>

        {/* lg + middle */}
        {/* <div>
            <h1 className='hidden lg:block pt-12 pb-14 text-center font-black text-worksans text-4xl'>{props.pagelabel}</h1>
        </div> */}

        {/* The Drop-down - for mobile, sm & md */}
        {showmenu && (<div className='absolute right-0 top-0 w-full z-10 text-center bg-verdantblack'>
            <div id="the button x" className='absolute right-3 top-6 sm:right-12 sm:top-5 md:right-14'>
                <button onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div> 

            
            <div id="" className='py-8 w-5/12 mx-auto text-left text-gray-100 font-worksans text-lg font-bold'>
                <div className='w-9/12 mx-auto sm:w-5/12 md:w-4/12'><Logo/></div>
                <div id="orange line" className='my-4 mx-auto border-b-2 border-orange-500'></div>
                <div id="the links" className='text-center'>
                    <Link to="/about" className='focus:text-orange-400'><p>About</p></Link>
                    <Link to="/services" className='focus:text-orange-400'><p className='py-2'>Services</p></Link>
                    <Link to="/projects" className='focus:text-orange-400'><p>Projects</p></Link>
                    <Link to="/contact" className='focus:text-orange-400'><p className='pt-2'>Contact</p></Link>
                </div>
                
            </div>
        </div>)}

        {/* The menu - for lg +*/}
        <div className='hidden lg:block w-11/12 mx-auto lg:flex lg:items-center justify-between'>
            <div id="logo" className='-mt-3'><Logo/></div>
            <nav className='pl-10 pr-4 py-2 lg:flex items-center space-x-10 justify-between bg-green-600 rounded-md uppercase text-sm text-gray-100 font-bold'>
                {/* <Link to="/"><p>Home</p></Link> */}
                <Link to="/about"><p>About</p></Link>
                <Link to="/services"><p>Services</p></Link>
                <Link to="/projects"><p>Projects</p></Link>
                <Link to="/contact"><p className='ml-2 px-3 py-1 bg-verdantblue rounded-md '>Contact</p></Link>
            </nav>
        </div>
        

      </main>
  )
}
