import React from 'react';

export default function Service(props) {
  return (
      <main>
          {/* Props - iconsvg, dspn*/}
          <div id="impact assessment" className='w-11/12 mx-auto lg:py-2'>
              <div id="icon" className='w-5/12 mx-auto'>
                {props.iconsvg}
              </div>
              <div id="orange line" className='w-2/3 mx-auto my-2 border-b-2 border-orange-400'></div>
              <p id="service description" className="text-center text-xs leading-tight lg:text-sm">{props.dspn}</p>
          </div>
      </main>
  );
}
